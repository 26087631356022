import { Router } from 'aurelia-router';
import { Accidente, TrabajadorAccidente } from './../../entities/actPrev';
import { autoinject, observable } from 'aurelia-framework';
import { Api } from 'utils/api';

@autoinject
export class AccidenteInfV2 {

  @observable title: string;
  acc: Accidente;

  constructor(private api: Api, public router: Router) {
  }

  activate(params) {
    this.title = this.api.tr("investigacion_acc_inf");
    // this.acc = params.acc;

    let accID = params.accidenteID;
    let empID = params.empID;
    let empCdT = params.empCdt;

    let pars = {
      empresa: empID,
      empresa_cdt: empCdT,
    };
    this.api.getOne(Accidente, 'accidentes', accID, pars)
      .then(item => {
        this.acc = item;

        let params = {
          empresa: empID,
          empresa_cdt: empCdT,
          accidente: accID
        }

        return this.api.getList(TrabajadorAccidente, 'accidentes_trabajadores', params)
          .then(items => {
            if (items) {
              this.acc.trabajadores = items;
            }
          });
      });
  }

  imprimir() {
    window.print();
  }

  goBack() {
    this.router.navigateBack();
  }

}
