import { Accidente } from './../../entities/actPrev';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { Trabajador } from 'entities/trabajador';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import { EmpresaCdT } from 'entities/centro';
import AuthService from 'authService';
import { EyActPrevTrabs } from 'components/ey-act-prev-trabs';
import moment from 'moment';
import { EyAccForm } from 'components/ey-acc-form';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class AccidenteEdit extends EyBasePage {

  accID: number;
  empID: number;
  empCdT: number;

  @observable acc: Accidente;
  empresaCdt: EmpresaCdT;
  @observable documentos: Documento[] = [];
  @observable trabList: Trabajador[] = [];
  docWidget: EyDocumentos;
  trabWidget: EyActPrevTrabs;
  filterType: FilterType = FilterType.POR_SUBIR;

  tab: string = "trabajadores";

  // saveChanges: boolean = false;
  lastChange: moment.Moment;

  // settedSub: Subscription;
  savedSub: Subscription;
  // trabSub: Subscription;
  docSub: Subscription;

  saveChanges: boolean = false;
  filter: FilterType = FilterType.POR_SUBIR;
  hasFilter: boolean = false;

  @observable dm_prod_mp: boolean = false;
  @observable dm_prod_ac: boolean = false;
  @observable dm_prod_sa: boolean = false;
  @observable dm_prod_otros: boolean = false;

  constructor(private api: Api, public router: Router, private ea: EventAggregator, private auth: AuthService) {
    super(router);
  }

  activate(params) {

    console.log("AccidenteEdit", params)

    this.accID = params.accidenteID;
    this.empID = params.empID;
    this.empCdT = params.empCdt;
    this.getCentro(this.empID, this.empCdT).then(() => {
      this.getAccidente();
    });

    if (params.filterType) {
      this.filter = Number(params.filterType);
      this.hasFilter = true;
    }
  }

  deactivate() {
    // this.trabSub.dispose();
  }

  // canDeactivate() {
  //   if (!this.vistaWidget) {
  //     return true;
  //   } else if (!this.vistaWidget.saveChanges) {
  //     return true
  //   }
  //   return confirm('Hay cambios sin guardar, ¿desea descartar los cambios?');
  // }

  attached() {
    if (this.docWidget) {
      this.docWidget.parent = this;
      this.docWidget.setFilter(this.filter);
      if (this.hasFilter) {
        this.selectTab('documentos');
      }
    }


    this.savedSub = this.eventAggregator.subscribe('accidente:saved', () => {
      this.lastChange = moment();
    });

    // this.settedSub = this.eventAggregator.subscribe('actprev:setted', () => {
    //   setTimeout(() => { this.saveChanges = false; }, 300);
    // });
  }

  actualizarTrabajadores() {
    console.log("actualizarTrabajadores", this.empresaCdt.trabajadoresCdt)
    this.trabList = [];
    this.trabList.push({ id: 0, nombreCompletoCif: this.api.tr("ninguno") } as Trabajador);
    if (this.empresaCdt && this.empresaCdt.trabajadoresCdt) {
      // Carga los trabajadores de la empresa que NO estén presentes en esta Actividad Preventiva

      for (let tCdt of this.empresaCdt.trabajadoresCdt) {
        if (!tCdt.presente) {
          continue;
        }

        let asignado = this.acc.trabajadores.find((tAcc) => { return (tAcc.trab_id == tCdt.trabajador.id); });
        if (asignado) {
          continue;
        }

        this.trabList.push(tCdt.trabajador);
      }
    }

    this.trabList.sort((a, b) => {
      if (a.nombreCompleto < b.nombreCompleto) { return -1; }
      if (a.nombreCompleto > b.nombreCompleto) { return 1; }
    });
  }

  detached() {
    this.savedSub.dispose();
    // this.settedSub.dispose();
  }

  getCentro(empID: number, empCdt: number): Promise<any> {

    if (!empID || !empCdt) {
      this.router.navigate('#/');
      return;
    }

    let params = {
      empresa: empID,
    }
    return this.api.getOne(EmpresaCdT, 'empresa_centros', empCdt, params)
      .then(item => {
        console.log("getCentros", item);
        if (item) {
          this.empresaCdt = item;
          if (!this.empresaCdt.empresaCached) {
            this.api.getEmpresas()
              .then(() => {
                this.empresaCdt.empresaCached = this.api.empresaByID(this.empresaCdt.empresa_id);
              })
          }
        }
      });
  }

  getAccidente() {

    let params = {
      empresa: this.empresaCdt.empresa_id,
      empresa_cdt: this.empresaCdt.id,
    }

    this.api.getOne(Accidente, 'accidentes', this.accID, params)
      .then(item => {
        this.acc = item;

        this.api.getTrabajadoresAccidente(this.empresaCdt, this.acc);


        this.dm_prod_mp = this.acc.dm_prod_mp == 1;
        this.dm_prod_ac = this.acc.dm_prod_ac == 1;
        this.dm_prod_sa = this.acc.dm_prod_sa == 1;
        this.dm_prod_otros = this.acc.dm_prod_otros == 1;
      });

  }

  editarAccidente() {
    if (!this.empresaCdt) {
      return;
    }

    this.api.showEditorDialog({ viewModel: EyAccForm, model: { parent: this, acc: this.acc, empresaCdt: this.empresaCdt } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          console.log("newAcc", response)
          this.lastChange = moment();
        }
      });
  }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos() {
    let params = { accidente: this.accID };
    this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
        }
      });
  }

  selectTab(tab: string) {
    this.tab = tab;

    if (this.tab == "documentos" || this.tab == "documentos_centro") {
      this.getDocumentos()
    } else if (this.tab == "trabajadores") {
      this.api.getTrabajadoresAccidente(this.empresaCdt, this.acc);
    } else if (this.tab == "materiales") {
      this.api.updateFormasDeContacto();
      this.api.updateAgentesMateriales();

      if (this.empresaCdt && this.empresaCdt.trabajadoresCdt && this.empresaCdt.trabajadoresCdt.length > 0) {
        this.actualizarTrabajadores();
      } else {
        this.api.getTrabajadoresCdt(this.empresaCdt)
          .then(() => this.actualizarTrabajadores());
      }
    }
  }

  changed() {
    // console.log("Changed", this.acc.dm_val_inst);
    // this.ea.publish('actprev:changed');
    this.saveChanges = true;

    // this.acc.dm_val_inst = Math.max(Number(this.acc.dm_val_inst), 0);
    // this.acc.dm_val_herr = Math.max(Number(this.acc.dm_val_herr), 0);
    // this.acc.dm_val_maq = Math.max(this.acc.dm_val_maq, 0);
    // this.acc.dm_val_otros = Math.max(this.acc.dm_val_otros, 0);
    // this.acc.dm_val_prod = Math.max(this.acc.dm_val_prod, 0);


    // console.log("Changed", this.acc.dm_val_inst);
  }

  guardarAccidente() {

    // cambiar checkboxes
    this.acc.dm_prod_mp = this.dm_prod_mp ? 1 : 0;
    this.acc.dm_prod_ac = this.dm_prod_ac ? 1 : 0;
    this.acc.dm_prod_sa = this.dm_prod_sa ? 1 : 0;
    this.acc.dm_prod_otros = this.dm_prod_otros ? 1 : 0;

    // this.acc.dm_val_herr = Math.max(this.acc.dm_val_herr, 0);
    // this.acc.dm_val_inst = Math.max(this.acc.dm_val_inst, 0);
    // this.acc.dm_val_maq = Math.max(this.acc.dm_val_maq, 0);
    // this.acc.dm_val_otros = Math.max(this.acc.dm_val_otros, 0);
    // this.acc.dm_val_prod = Math.max(this.acc.dm_val_prod, 0);

    if (this.acc.dm_val_herr < 0 ||
      this.acc.dm_val_inst < 0 ||
      this.acc.dm_val_maq < 0 ||
      this.acc.dm_val_otros < 0 ||
      this.acc.dm_val_prod < 0) {
      this.api.alert(this.api.tr("valores_negativos"), this.api.tr("valores_negativos_dm"));
      return;
    }

    this.api.saveOne(Accidente, this.acc, 'accidentes', this.acc.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }

        this.lastChange = moment();
        this.saveChanges = false;

        this.getAccidente();
      })
      .catch(error => {
      });
  }

  informeAccidente() {

    // actualizar trabajadores
    // this.api.getTrabajadoresActPrev(this.empresaCdt, this.actividadPreventiva);

    // this.api.showEditorDialog({ viewModel: AccidenteInf, model: { acc: this.acc } });

    let params = {
      empID: this.empresaCdt.empresa_id,
      empCdt: this.empresaCdt.id,
      accidenteID: this.acc.id
    };
    this.router.navigateToRoute('accidenteInforme', params);
  }

  informeAccidenteV2() {

    let params = {
      empID: this.empresaCdt.empresa_id,
      empCdt: this.empresaCdt.id,
      accidenteID: this.acc.id
    };

    if (this.acc.inv_abiertas > 0) {

      this.api.confirm(this.api.tr("error_detectado"), this.api.tr("inf_abierto_confirmar"))
        .then(b => {
          if (!b)
            return;

          this.router.navigateToRoute('accidenteInformeV2', params);
        });
    } else {

      this.router.navigateToRoute('accidenteInformeV2', params);
    }


  }
}
