import { Accidente, TrabajadorAccidente } from './../../entities/actPrev';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { Trabajador } from 'entities/trabajador';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import { EmpresaCdT } from 'entities/centro';
import { TrabajadorCdt } from 'entities/trabajadorCdt';
import AuthService from 'authService';
import moment from 'moment';
import { EyAccTrabForm } from 'components/ey-acc-trab-form';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class AccidenteTrabEdit extends EyBasePage {

  accID: number;
  trabAccID: number;
  empID: number;
  empCdT: number;

  acc: Accidente;
  @observable empresaCdt: EmpresaCdT;
  @observable trabAcc: TrabajadorAccidente;
  @observable documentos: Documento[] = [];
  docWidget: EyDocumentos;

  @observable tab: string = "documentos";
  @observable self: AccidenteTrabEdit;

  ready: boolean = false;
  @observable saveChanges: boolean = false;
  @observable lastChange: moment.Moment;
  filter: FilterType = FilterType.POR_SUBIR;
  hasFilter: boolean = false;


  constructor(private api: Api, public router: Router, private ea: EventAggregator, private auth: AuthService) {
    super(router);

    this.self = this;
  }

  activate(params) {

    console.log("AccidenteEdit", params)

    this.accID = params.accidenteID;
    this.trabAccID = params.accidenteTrabID;
    this.empID = params.empID;
    this.empCdT = params.empCdt;
    this.getCentro(this.empID, this.empCdT).then(() => {
      this.getAccidente();
    });

    if (params.filterType) {
      this.filter = Number(params.filterType);
      this.hasFilter = true;
    }
  }

  canDeactivate() {
    if (this.ready && this.saveChanges) {
      return confirm(this.api.tr("cambios_sin_guardar"));
    }
    return true;
  }

  deactivate() {
    // this.trabSub.dispose();
    // this.docSub.dispose();
  }

  attached() {
    if (this.docWidget) {
      this.docWidget.parent = this;
      this.docWidget.setFilter(this.filter);
      if (this.hasFilter) {
        this.selectTab('documentos');
      }
    }

    if (!this.auth.authData.acc_trab_doc_ver) {
      this.tab = "investigacion"
    }
    this.selectTab(this.tab);

    // Forzar esperar un poco antes de cambiar cosas
    setTimeout(() => {
      this.ready = true;
      this.saveChanges = false
    }, 2 * 1000);
  }

  detached() {
  }

  changed() {
    if (!this.ready) {
      return;
    }
    this.saveChanges = true;
  }

  resetSaveFlags() {
    this.lastChange = moment();
    this.saveChanges = false;
  }

  getCentro(empID: number, empCdt: number): Promise<any> {

    if (!empID || !empCdt) {
      this.router.navigate('#/');
      return;
    }

    let params = {
      empresa: empID,
    }
    return this.api.getOne(EmpresaCdT, 'empresa_centros', empCdt, params)
      .then(item => {
        console.log("getCentros", item);
        if (item) {
          this.empresaCdt = item;
          if (!this.empresaCdt.empresaCached) {
            this.api.getEmpresas()
              .then(() => {
                this.empresaCdt.empresaCached = this.api.empresaByID(this.empresaCdt.empresa_id);
              })
          }
        }
      });
  }

  getAccidente() {

    let params = {
      empresa: this.empresaCdt.empresa_id,
      empresa_cdt: this.empresaCdt.id,
    }

    this.api.getOne(Accidente, 'accidentes', this.accID, params)
      .then(item => {
        this.acc = item;

        this.getAccidenteTrab();
      }).then(

      );
  }

  getAccidenteTrab() {

    let params = {
      empresa: this.empresaCdt.empresa_id,
      empresa_cdt: this.empresaCdt.id,
      accidente: this.accID
    }

    // this.api.getTrabajadoresAccidente(this.empresaCdt, this.acc);
    this.api.getOne(TrabajadorAccidente, "accidentes_trabajadores", this.trabAccID, params).then(item => {
      this.trabAcc = item;
    });
  }


  editarTrabAccidente() {
    if (!this.empresaCdt) {
      return;
    }

    this.api.showEditorDialog({ viewModel: EyAccTrabForm, model: { acc: this.acc, empresaCdt: this.empresaCdt, trabAcc: this.trabAcc } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          this.getAccidenteTrab();
        }
      });
  }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos() {
    let params = { accidente_trab: this.trabAccID };
    this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
        }
      });
  }

  selectTab(tab: string) {
    this.tab = tab;

    if (this.tab == "documentos") {
      this.getDocumentos();
    }
  }

  save() {
    this.api.saveOne(TrabajadorAccidente, this.trabAcc, 'accidentes_trabajadores', this.trabAcc.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }

        this.resetSaveFlags();
      });
  }

}
