// / <reference types="aurelia-loader-webpack/src/webpack-hot-interface"/>
// we want font-awesome to load as soon as possible to show the fa-spinner
import 'reflect-metadata';
import { Aurelia } from 'aurelia-framework'
import environment from './environment';
import { PLATFORM } from 'aurelia-pal';
// import * as Bluebird from 'bluebird';
import { DialogConfiguration } from 'aurelia-dialog';
import { I18N, TCustomAttribute, Backend } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AppRouter } from 'aurelia-router';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import 'bootstrap';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'toastr/build/toastr.min.css';
import * as $ from 'jquery';

import 'app.scss';

import AuthService from 'authService';

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
// Bluebird.config({ warnings: { wForgottenReturn: false } });

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin(PLATFORM.moduleName('aurelia-dialog'), (config: DialogConfiguration) => {
      config.useDefaults();
      config.settings.startingZIndex = 1200;
    })
    .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
      let aliases = ['t', 'i18n'];
      // add aliases for 't' attribute
      TCustomAttribute.configureAliases(aliases);

      // register backend plugin
      instance.i18next.use(Backend.with(aurelia.loader));

      // adapt options to your needs (see http://i18next.com/docs/options/)
      // make sure to return the promise of the setup method, in order to guarantee proper loading
      return instance.setup({
        backend: {                                  // <-- configure backend settings
          loadPath: 'locales/{{lng}}/{{ns}}.json', // <-- XHR settings for where to get the files from
        },
        attributes: aliases,
        lng: 'es',
        fallbackLng: 'es',
        debug: false
      }).then(() => {
        const router = aurelia.container.get(AppRouter);
        const eventAggregator = aurelia.container.get(EventAggregator);
        router.transformTitle = title => instance.tr(title);
        eventAggregator.subscribe('i18n:locale:changed', () => {
          router.updateTitle();
        });
      });
    })
    .feature(PLATFORM.moduleName('components/index'))
    .feature(PLATFORM.moduleName('resources/index'));

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  }

  aurelia.start()
    .then(() => {
      var auth = aurelia.container.get(AuthService);
      console.log("Main auth", auth.isAuthenticated());
      let root = auth.isAuthenticated() ? PLATFORM.moduleName('app') : PLATFORM.moduleName('public');
      aurelia.setRoot(root);

      // const router = aurelia.container.get(AppRouter);
      //       router.navigate('/', { replace: true, trigger: false });
      //       aurelia.setRoot(PLATFORM.moduleName('privateRoot'));
    });
}
